import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProductsService from "../../api/products";


const initialState = {
    hasSearchResults: false,
    searchTerm: "",
    results: [],
};

function getFormattedResults(algoliaResults){
    let newObjArr = []
    algoliaResults.map((item) => {
        newObjArr.push(item.godi_hirdirinn_cms__Product)
    })
    return newObjArr
}


const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setSearchResults(state, results){
            state.hasSearchResults = results.payload.length > 0,
            state.results = getFormattedResults(results.payload)
        },
        setSearchTerm(state, term){
            state.searchTerm = term.payload
        },
        clearSearch(state){
            state.hasSearchResults = false
            state.searchTerm = ""
            state.results = []
        }
    }
})

export const { setSearchResults, setSearchTerm, clearSearch } = searchSlice.actions
const { reducer } = searchSlice;
export default reducer;