import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProductsService from "../../api/products";


export const getProducts = createAsyncThunk(
    "products/get",
    async (args, thunkAPI) => {
        try {
            const response = await ProductsService.getProducts(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const getSingleProduct = createAsyncThunk(
  "products/getSingle",
  async (args, thunkAPI) => {
      try {
          const response = await ProductsService.getProducts(`${args.id}/`);
          return response;
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue(message);
      }
  }
);

export const getProductsByCategory = createAsyncThunk(
  "products/get",
  async (args, thunkAPI) => {
      try {
          const response = await ProductsService.getProductsByCategory(args);
          return response;
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue(message);
      }
  }
);

export const getProductBlockProducts = createAsyncThunk(
  "productblock/get",
  async (args, thunkAPI) => {
    try {
      const response = await ProductsService.getProducts(args.filters);
      return {[args.block_id]: response};
    } catch (error) {
      const message =
        (error.response &&
          error.response.data ) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setSelectedProduct = createAsyncThunk(
    "product/set",
    async (product, thunkAPI) => {
      try {
        return product;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data ) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  

const initialState = {
    results: [],
    selectedProduct: null,
    loading: false,
    loadingDetail: false,
    productBlockList: {},
};

const productsSlice = createSlice({
    name: "products",
    initialState,
    extraReducers: {
        [getProducts.pending]: (state, action) => {
            state.loading = true;
        },
        [getProducts.fulfilled]: (state, action) => {
            state.results = action.payload
            state.loading = false;
        },
        [getProducts.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja vörur";
            state.loading = false;
        },
        [getSingleProduct.pending]: (state, action) => {
            state.loading = true;
        },
        [getSingleProduct.fulfilled]: (state, action) => {
            state.selectedProduct = action.payload
            state.loading = false;
        },
        [getSingleProduct.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja vöru";
            state.loading = false;
        },
        [getProductsByCategory.pending]: (state, action) => {
          state.loading = true;
        },
        [getProductsByCategory.fulfilled]: (state, action) => {
          state.results = action.payload
          state.loading = false;
        },
        [getProductsByCategory.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja vörur";
            state.loading = false;
        },
        [getProductBlockProducts.pending]: (state, action) => {
          state.loading = true;
        },
        [getProductBlockProducts.fulfilled]: (state, action) => {
          state.productBlockList = {...state.productBlockList, ...action.payload};
          state.loading = false;
        },
        [getProductBlockProducts.rejected]: (state, action) => {
          state.error = "Ekki tókst að sækja vörur";
          state.loading = false;
        },
        [setSelectedProduct.pending]: (state, action) => {
            state.loading = true;
          },
        [setSelectedProduct.fulfilled]: (state, action) => {
        state.selectedProduct = action.payload;
        state.loading = false;
        },
        [setSelectedProduct.rejected]: (state, action) => {
        state.error = "Ekki tókst að velja vöru";
        state.loading = false;
        },
    },
});

const { reducer } = productsSlice;
export default reducer;