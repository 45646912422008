 import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { login, setShowLogin } from "../../slices/auth";
import { SVGPinkBg } from "../svg";
import ForgotPassword from "./forgotten-password";

export default function Login() {
    const dispatch = useDispatch();
    const authenticated = useSelector((state) => state.auth.isLoggedIn);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [failedLoginError, setFailedLoginError] = useState(null);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const translations = [
        gettext('Email'),
        gettext('This field is required'),
        gettext('Invalid email address'),
        gettext('Password'),
        gettext('This field is required'),
        gettext('Forgot password'),
        gettext('Login')
    ]

    const onLogin = async (data) => {
        try {
            await dispatch(login({
                username: data.email,
                password: data.password,
            })).unwrap();

            // If login is successful, dispatch setShowLogin with false
            dispatch(setShowLogin(false));
        } catch (error) {
            // Handle the error here
            setFailedLoginError(error);
        }
    };

    return (
        showForgotPassword ? (
            <ForgotPassword />
        ) : (
        <div className="container login-component">
            <div className="row justify-content-center d-flex">
                <div className="col-lg-8 col-xl-6 col-15 mt-5">
                    {failedLoginError && failedLoginError.error}
                    <form onSubmit={handleSubmit(onLogin)}>
                        <div className="form-group position-relative mb-3 text-start">
                            <label className="form-label">{gettext('Email')}</label>
                            <input
                                placeholder=""
                                type="text"
                                 className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                {...register('email', {
                                    required: gettext('This field is required'),
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: gettext('Invalid email address'),
                                    },
                                })}
                            />
                            {
                                errors.email && (
                                    <div className="error">{errors.email.message}</div>
                                )
                            }
                        </div>

                        <div className="form-group position-relative mb-3 text-start">
                            <label className="form-label">{gettext('Password')}</label>
                            <input
                                placeholder=" "
                                type="password"
                                 className={`form-control ${errors.password ? "is-invalid" : ""}`}
                                {...register('password', {
                                    required: gettext('This field is required'),
                                })}
                            />
                            {
                                errors.password && (
                                    <div className="error">{errors.password.message}</div>
                                )
                            }
                        </div>
                        <div className="d-flex mb-3">
                            <button type="button" className="btn btn-link forgotten-password" onClick={() => setShowForgotPassword(true)}>{gettext('Forgot password')}</button>
                        </div>
                        <div className="d-flex justify-content-center mb-3"> 
                         <button type="submit" className="btn position-relative font-godi">
                                {gettext('Login')}
                                <div className="position-absolute svg-container h-100"><SVGPinkBg /></div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        )
    );
}
