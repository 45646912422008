import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductList from '../../components/product_list';
import { getProductBlockProducts } from '../../slices/products';
import ProductDetail from '../../components/product_detail';
import { SVGMoreButton } from '../../components/svg';

export default function ProductblockContainer(props) {
    const dispatch = useDispatch()
    const products = useSelector(state => state.products.productBlockList && state.products.productBlockList[props.blockId] || [])
    const selectedProduct = useSelector(state => state.products.selectedProduct)

    const modalId = useSelector(state => state.modals.activeModal)

    useEffect(() => {
        dispatch(getProductBlockProducts({'filters': props.filters, 'block_id': props.blockId}))
    }, [])


    useEffect(() => {
        if(selectedProduct) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [selectedProduct])
    
    return (
        products && products.length > 0 && 
        <>
            <div className="row justify-content-center product-block">
                <div className='col-lg-12 col-16 position-relative'>
                    {props.title && <h2 className='product-block-title'>{props.title}</h2>}
                </div>
                <div className="w-100"></div>
                {products && <ProductList products={products} blockId={props.blockId} />}
                <div className="w-100 text-center mt-3">
                    <a href={`/shop${props.shopfilters}`} className="more-button font-godi a-tag"><SVGMoreButton />{props.buttontext}</a>
                </div>
            </div>
            { selectedProduct && modalId?.includes(props.blockId) && (
               <ProductDetail selectedProduct={selectedProduct} productList={products} />
            )}
        </>
    );
} 
