import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import OrdersService from "../../api/orders";


export const getOrders = createAsyncThunk(
  "orders/get",
  async (thunkAPI) => {
    try {
      const response = await OrdersService.getOrders();
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { orders: [] };

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  extraReducers: {
    [getOrders.fulfilled]: (state, action) => {
      state.orders = action.payload
      state.error = null
    },
    [getOrders.rejected]: (state, action) => {
      state.orders = []
      state.error = "Ekki tókst að sækja pantanir"
      state.tokenStatus = null
    },
  },
});

const { reducer } = ordersSlice;
export default reducer;