import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getCurrentUser, updateUser } from "../../slices/auth";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CreditCardForm from "../../components/creditcard-form";
import { getCustomer } from "../../slices/payments";
import { SVGPinkBg } from "../svg";
import OrderList from "../order_list";

const translations = [
    gettext('My pages'),
    gettext('Personal information'),
    gettext('My orders'),
    gettext('First name'),
    gettext('Field cannot be blank'),
    gettext('Last name'),
    gettext('Email'),
    gettext('Phone'),
    gettext('Password'),
    gettext('Passwords needs to be the same'),
    gettext('Repeat Password'),
    gettext('Submit'),
    gettext('Successfully updated'),
]

export default function MyPages() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth?.currentUser);
    const askellUser = useSelector((state) => state.payments?.customer);

    const [tabIndex, setTabIndex] = useState(0);

    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(() => {
        dispatch(getCurrentUser());
    }, [dispatch]);

    const { register, reset, handleSubmit, getValues, formState: { errors } } = useForm({
        defaultValues: {
            first_name: user?.first_name,
            last_name: user?.last_name,
            email: user?.email,
            phone: user?.phone,
            password1: '',
            password2: ''
        }
    });

    useEffect(() => {
        dispatch(getCustomer())
    }, [])

    useEffect(() => {
        if (user) {
            reset({
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
                password1: '',
                password2: ''
            });
        }
    }, [user, reset]);

    useEffect(() => {
        setIsSuccess(false)
        console.log(isSuccess)
    }, [])

    const onSubmit = async (data) => {
        const {first_name, last_name, email, phone, password1, password2} = data

        Promise.resolve(dispatch(updateUser({id: user?.id, data: {first_name, last_name, email, phone, password1, password2}})))
            .then((data) => setIsSuccess(true))

            console.log(isSuccess)

    };


    return (
        <div className="container-fluid clamped my-pages pb-5">
            {user &&
                <>
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-15 position-relative">
                            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                                <div className="d-flex flex-wrap w-100 border-bottom">
                                    <h1 className="d-inline-block mb-0">{gettext('My pages')}</h1>
                                    <TabList className='d-flex position-relative mb-0'>
                                        <Tab className="list-unstyled position-relative" onClick={() => setTabIndex(0)} style={{ outline: 'none' }}>
                                            <span className="font-var-550">{gettext('Personal information')}</span>
                                        </Tab>
                                        <Tab className="list-unstyled position-relative" onClick={() => setTabIndex(1)} style={{ outline: 'none' }}>
                                            <span className="font-var-550">{gettext('My orders')}</span>
                                        </Tab>
                                    </TabList>
                                </div>
                                <TabPanel>
                                    <div className="row justify-content-center pt-5">
                                        <div className="col-lg-8 col-16">
                                            <form onSubmit={handleSubmit(onSubmit)} className="mb-3">
                                                <div className="mb-3">
                                                    <label htmlFor="first_name" className="form-label">{gettext('First name')}</label>
                                                    <input
                                                        type="text"
                                                        {...register("first_name", { required: gettext('Field cannot be blank') })}
                                                        className={`form-control ${errors.first_name ? "is-invalid" : ""}`}
                                                        id="first_name"
                                                    />
                                                    {errors.first_name && <div className="invalid-feedback">{errors.first_name.message}</div>}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="last_name" className="form-label">{gettext('Last name')}</label>
                                                    <input
                                                        type="text"
                                                        {...register("last_name", { required: gettext('Field cannot be blank') })}
                                                        className={`form-control ${errors.last_name ? "is-invalid" : ""}`}
                                                        id="last_name"
                                                    />
                                                    {errors.last_name && <div className="invalid-feedback">{errors.last_name.message}</div>}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">{gettext('Email')}</label>
                                                    <input
                                                        type="text"
                                                        {...register("email", { required: gettext('Field cannot be blank') })}
                                                        className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                                        id="email"
                                                    />
                                                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="phone" className="form-label">{gettext('Phone')}</label>
                                                    <input
                                                        type="text"
                                                        {...register("phone", { required: gettext('Field cannot be blank') })}
                                                        className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                                                        id="phone"
                                                    />
                                                    {errors.phone && <div className="invalid-feedback">{errors.phone.message}</div>}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="password1" className="form-label">{gettext('Password')}</label>
                                                    <input
                                                        type="password"
                                                        {...register("password1", {
                                                            validate: value => value === getValues().password2 || gettext('Passwords needs to be the same')
                                                        })}
                                                        className={`form-control ${errors.password1 ? "is-invalid" : ""}`}
                                                        id="password1"
                                                    />
                                                    {errors.password1 && <div className="invalid-feedback">{errors.password1.message}</div>}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="password2" className="form-label">{gettext('Repeat Password')}</label>
                                                    <input
                                                        type="password"
                                                        {...register("password2", {
                                                            validate: value => value === getValues().password1 || gettext('Passwords needs to be the same')
                                                        })}
                                                        className={`form-control ${errors.password2 ? "is-invalid" : ""}`}
                                                        id="password2"
                                                    />
                                                    {errors.password2 && <div className="invalid-feedback">{errors.password2.message}</div>}
                                                </div>
                                                {isSuccess && <div className="alert alert-success" role="alert">{gettext('Successfully updated')}</div>}
                                                <button type="submit" className="btn position-relative font-godi">
                                                    {gettext('Submit')}
                                                    <div className="position-absolute svg-container h-100"><SVGPinkBg /></div>
                                                </button>
                                            </form>
                                        </div>
                                        <div className="col-lg-8 col-16">
                                            {askellUser && askellUser.payment_method ?
                                                    askellUser.payment_method.length > 0 ?
                                                    <>
                                                        <p>Þú hefur skráð eftirfarandi kortaupplýsingar:</p>
                                                        {askellUser.payment_method.map((card, index) => (
                                                            <div key={index}>
                                                                <p>{card.display_info} {card.valid_until}</p>
                                                            </div>
                                                        ))}
                                                        <p>Til að skipta um kort, vinsamlegast fylltu út formið hér fyrir neðan.</p>
                                                        <CreditCardForm />
                                                    </>
                                                    :
                                                    <>
                                                        <p>Þú hefur ekki skráð neinar kortaupplýsingar, vinsamlegast fylltu út formið hér að neðan til að skrá kort.</p>
                                                        <CreditCardForm />
                                                    </>
                                                :
                                                <p>Sæki viðskiptamannaupplýsingar...</p>
                                            }
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="row justify-content-center pt-5">
                                        <OrderList />
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}
