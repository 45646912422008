import { createSlice } from '@reduxjs/toolkit'
import { setSelectedProduct } from '../products'

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    activeModal: null,
  },
  reducers: {
    setActiveModal: (state, action) => {
      if (Object.keys(action.payload).includes('activeModal')) {
        state.activeModal = action.payload.activeModal
      } else {
        state.activeModal = action.payload
      }
    },
    closeModal: (state, action) => {
        state.activeModal = null
    }
  },
})

// Action creators are generated for each case reducer function
export const { closeModal, setActiveModal } = modalSlice.actions

export default modalSlice.reducer