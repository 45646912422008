import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/js/bootstrap.min.js";
import '../sass/main.scss';

require('url-search-params-polyfill');
require ('./containers/product-block-container/index.js');
require ('./containers/webshop-container/index.js');
require ('./containers/search-container/index.js');
require ('./containers/minar-sidur-container/index.js');
require ('./containers/userbar-container/index.js');
require ('./containers/loginmodal-container/index.js');