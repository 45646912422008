import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../../store'
import MinarSidur from '../../components/minar_sidur';
import { BrowserRouter as Router } from 'react-router-dom';

export default function Wrapper(props) {
    return (
        <Router>
            <Provider store={store}>
                <MinarSidur {...props}/>
            </Provider>
        </Router>
    )
}