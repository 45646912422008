import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../../store'
import ProductblockContainer from './productblock-container';

export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <ProductblockContainer {...props}/>
        </Provider>
    )
}