import axios from "axios";
import { getCookie } from "../../utils";
import { API_BASE_PATH } from "../../const";

const getOrders = () => {
    return axios
        .get(API_BASE_PATH + `orders/`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};


const ordersService = {
    getOrders,
};

export default ordersService;