import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginModal from '../../components/modals/login_modal';

export default function LoginModalContainer(props) {

    const showLogin = useSelector(state => state.auth.showLogin);
    
    return (
        showLogin && <LoginModal />
    );
} 
