import React, { useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import Userbar from '../../components/userbar'
import { getCurrentUser } from "../../slices/auth";
import { getCustomer } from '../../slices/payments';
import { getSingleProduct } from '../../slices/products';

export default function UserbarContainer(props) {
    const { user: currentUser } = useSelector((state) => state.auth);

    const hash = window.location.hash

    const dispatch = useDispatch()
    useEffect(() => {
        Promise.resolve(dispatch(getCurrentUser()))
        .then((data) => {
            if (data.payload && data.payload.authenticated) {
                dispatch(getCustomer())
            }
            if (hash && hash.startsWith('#')) {
                dispatch(getSingleProduct({id: parseInt(hash.replace('#', ''))}))
            }
        })
    }, [])

    return (
        <>
        <Userbar />
        </>
    )
  }