import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import Login from "../auth/login"
import Signup from "../auth/signup"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { SVGCloseSearchIcon, SVGLoginButtonBg, SVGSignupButtonBg } from "../svg";
import { setShowLogin } from "../../slices/auth";

const LoginModal = () => {

    const [tabIndex, setTabIndex] = useState(0);
    const dispatch = useDispatch();
    const translations = [
        gettext('Login'),
        gettext('Signup')
    ]

    return (
        <>
            <div className="godi-modal--overlay overflow-scroll" onClick={() => dispatch(setShowLogin(false))}></div>
            <div className="godi-modal--wrapper">
                <div className="godi-modal">
                    <div className="godi-modal--header">
                        <button
                            type="button"
                            className="godi-modal--close-button"
                            onClick={() => dispatch(setShowLogin(false))}
                        >
                            <SVGCloseSearchIcon />
                        </button>
                    </div>
                    <div className="godi-modal--body">
                        <Tabs className="border-0" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                            <TabList className='d-flex justify-content-center position-relative'>
                                <Tab className="list-unstyled position-relative" onClick={() => setTabIndex(0)} style={{ outline: 'none' }}>
                                    <span className="position-relative font-godi">{gettext('Login')}</span>
                                    <div className="position-absolute svg-container h-100 top-0 login"><SVGLoginButtonBg /></div>
                                </Tab>
                                <Tab className="list-unstyled position-relative" onClick={() => setTabIndex(1)} style={{ outline: 'none' }}>
                                    <span className="position-relative font-godi">{gettext('Signup')}</span>
                                    <div className="position-absolute svg-container h-100 top-0 signup"><SVGSignupButtonBg /></div>
                                </Tab>
                            </TabList>
                            <TabPanel>
                                <Login />
                            </TabPanel>
                            <TabPanel className="pt-md-5">
                                <Signup />
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginModal