import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../../slices/orders';

import moment from 'moment'
moment.locale('is')

function OrderList() {

    const dispatch = useDispatch()
    const orders = useSelector(state => state.orders.orders)

    useEffect(() => {
        dispatch(getOrders())
    }, [])

    return (
        <>
            <div className="col-16 pt-5 order-list">
                <div className='table overflow-scroll'>
                    <table className='w-100'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Vara</th>
                                <th>Dags.</th>
                                <th>Tilvísun</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order, index) => {
                                return (
                                    order.products.map(product => {
                                        return <>
                                            <tr key={index}>
                                                <td className='ps-0'><img src={product.images.primary} /></td>
                                                <td>
                                                    <h3>{product.title}</h3>
                                                    <span className='font-var-550'>{order.total} kr.</span>
                                                </td>
                                                <td className='date'>{moment(order.created_at).format('D. MMMM YYYY')}</td>
                                                <td className='ref'>{order.reference_short}</td>
                                            </tr>
                                        </>
                                    })
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default OrderList;