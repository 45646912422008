import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../../store'
import UserbarContainer from './userbar-container';

export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <UserbarContainer {...props}/>
        </Provider>
    )
}