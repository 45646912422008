import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedProduct } from "../../slices/products";
import { setActiveModal } from "../../slices/modal";
import { MODAL_PRODUCT_DETAIL } from "../../const";
import { formatNumber } from "../../utils";
import { SVGGreenBg, SVGLastChance, SVGOrangeBg, SVGUtsala } from "../svg";

export const ProductCard = ({ product, blockId }) => {
  const dispatch = useDispatch();

  const setProductAsSelected = (product) => {
    dispatch(setSelectedProduct(product));
    dispatch(setActiveModal(`${MODAL_PRODUCT_DETAIL} ${blockId}`));
  };

  const buyProduct = () => {
    console.log("Buy: ", product);
  };

  return (
    <div className="card mb-5">
      <div
        className="card-body p-0"
        onClick={() => setProductAsSelected(product)}>
        <div className="card-image">
          {product.price*2 == product.base_price && (
            product.last_chance ? <SVGLastChance /> : <SVGUtsala />
          )}
          <img
            src={product.images.card}
            className="card-img"
            alt={product.title}
          />
        </div>
        <div className="price">
        {product.base_price !== product.price ? <SVGOrangeBg /> : <SVGGreenBg /> }
          {product.base_price !== product.price && (
            <span className="font-godi strikethrough font-small">
              {formatNumber(product.base_price)}kr
            </span>
          )}
          <span className="font-godi">{formatNumber(product.price)}kr</span>
        </div>
      </div>
    </div>
  );
};
