import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../api/auth";

export const login = createAsyncThunk(
    "auth/login",
    async ({ username, password }, thunkAPI) => {
        try {
            const data = await AuthService.login(username, password);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            //thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const registerUser = createAsyncThunk(
    "auth/register",
    // async ({ username, password }, thunkAPI) => {
    async (user_data, thunkAPI) => {
        try {
            const data = await AuthService.register(user_data);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            //thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const logout = createAsyncThunk("auth/logout", async () => {
    await AuthService.logout();
});

export const passwordChange = createAsyncThunk(
    "auth/passwordChange",
    async (args, thunkAPI) => {
        try {
            const data = await AuthService.passwordChange(args);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            //thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const passwordReset = createAsyncThunk(
    "auth/passwordReset",
    async (args, thunkAPI) => {
        try {
            const data = await AuthService.passwordReset(args);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            //thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getCurrentUser = createAsyncThunk(
    "currentUser/get",
    async (args, thunkAPI) => {
        try {
            const response = await AuthService.getCurrentUser(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async ({id, data}, thunkAPI) => {
        try {
            const updatedUser = await AuthService.updateUser({id,data})
            return updatedUser
        } catch(err) {
            const message =
                (err.response &&
                    err.response.data) ||
                err.message ||
                err.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    currentUser: null,
    loading: false,
    loadingDetail: false,
    showLogin: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setShowLogin: (state, action) => {
            state.showLogin = action.payload;
        },
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            console.log("Login fulfilled: ", action)
            state.currentUser = action.payload.user;
        },
        [login.rejected]: (state, action) => {
            state.error = "Ekki tókst að auðkenna notanda.";
            state.currentUser = null;
        },
        [logout.fulfilled]: (state, action) => {
            state.currentUser = {};
        },
        [getCurrentUser.pending]: (state, action) => {
            state.loading = true;
        },
        [getCurrentUser.fulfilled]: (state, action) => {
            state.currentUser = action.payload;
            state.loading = false;
        },
        [getCurrentUser.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja notanda";
            state.currentUser = {};
            state.loading = false;
        },
        [updateUser.pending]: (state) => {
            state.loading = true;
        },
        [updateUser.fulfilled]: (state, action) => {
            state.currentUser = action.payload;
            state.loading = false;
        },
        [updateUser.rejected]: (state) => {
            state.error = "Ekki tókst að sækja notanda";
            state.currentUser = {};
            state.loading = false;
        },
    },
});

export const { setShowLogin } = authSlice.actions;
const { reducer } = authSlice;
export default reducer;