import accounting from 'accounting';

export const getCookie = (name) => {
  var cookieValue = null;
  if (document.cookie && document.cookie != '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) == (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const formatNumber = (num) => {
  return accounting.formatMoney(+num, '', 0, ".", ",")
}

export const getAnchor = () => {
  return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
}

export const setAnchor = (anchor) => {
  window.location.hash = anchor;
}

export const removeAnchor = () => {
  history.replaceState({}, document.title, ".");
}
