import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProducts, getProductsByCategory } from '../../slices/products';
import { useSearchParams } from 'react-router-dom';
import ProductDetail from '../../components/product_detail';
import ProductList from '../../components/product_list';



export default function WebshopContainer(props) {

    const dispatch = useDispatch()
    const categories = useSelector((state) => state.products.results);
    const loadingProducts = useSelector((state) => state.products.loading);
    const selectedProduct = useSelector(state => state.products.selectedProduct)
    const [showCategories, setShowCategories] = useState(false);

    /* Get URL params, coming from frontpage or copy/paste URL */
    let [searchParams, setSearchParams] = useSearchParams();
    const selectedTags = searchParams.get("t") || ""
    const selectedCategories = searchParams.get("c") || ""
    const last_chance = searchParams.get("lc") || ""

    function buildQueryParams() {
        let query_params = "?";
        if (selectedTags !== "") {
            query_params += `t=${selectedTags}&`;
        }
        if (selectedCategories !== "") {
            query_params += `c=${selectedCategories}&`;
        }
        if (last_chance !== "") {
            query_params += `lc=${last_chance}&`;
        }

        // Remove trailing '&' if it exists
        if (query_params.endsWith("&")) {
            query_params = query_params.slice(0, -1);
        }
        return query_params;
    }

    /* Fetch all products on init based on initial params */
    useEffect(() => {
        if (searchParams.size > 0) {
            dispatch(getProducts(buildQueryParams()))
        }
        else {
            dispatch(getProductsByCategory())
        }
    }, [])

    return (
        <div className="row justify-content-center product-block">
            {categories.length > 0 ?
                categories.map((category, index) =>
                    <>
                        <div className="w-100"></div>
                        <div key={index} className='col-lg-12 col-16 position-relative'>
                            <h2 className='product-block-title'>{category.title}</h2>
                        </div>
                        <div className="w-100"></div>
                        {category.products.length > 0 ?
                            <>
                                <ProductList products={category.products} show_more_button={categories.length > 1} button_filter={`?c=${category.id}`} />
                                
                            </>
                            :
                            <div className="w-100 text-center">Engar vörur fundust</div>
                        }
                        {selectedProduct && category.products.includes(selectedProduct) && (
                            <ProductDetail selectedProduct={selectedProduct} productList={category.products} />
                        )}
                    </>
                )
                :
                <h2 className="w-100 text-center product-block-title">Sæki vörur, augnablik...</h2>
            }
        </div>
    );
}