import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';

import productsReducer from './slices/products'
import authReducer from './slices/auth'
import modalReducer from './slices/modal'
import paymentReducer from './slices/payments'
import searchReducer from './slices/search'
import ordersReducer from './slices/orders'

const loggerMiddleware = createLogger()

const reducers = combineReducers({
    products: productsReducer,
    auth: authReducer,
    modals: modalReducer,
    payments: paymentReducer,
    search: searchReducer,
    orders: ordersReducer,
})

let middleware = null
if (process.env.NODE_ENV === 'production') {
    middleware = applyMiddleware(
        thunkMiddleware,
    )
} else {
    middleware = composeWithDevTools(applyMiddleware(
    thunkMiddleware,
    loggerMiddleware,
))}

export const store = createStore(reducers, middleware)
