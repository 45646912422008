import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedProduct } from "../../slices/products";
import { useClickAway } from '@uidotdev/usehooks'
import { SVGArrowNext, SVGPinkBg, SVGCloseSearchIcon, SVGPinkBorder, SVGGreenBg, SVGMoreButton, Checked, Unchecked, SVGOrangeBg, SVGLastChance, SVGUtsala } from "../svg";
import { setAnchor, removeAnchor } from "../../utils";
import { singleClickPurchase } from "../../slices/cart";
import { useSwipeable } from 'react-swipeable';
import { getPayment } from "../../slices/payments";
import { formatNumber } from '../../utils';
import CreditCardForm from "../../components/creditcard-form";
import { setShowLogin } from "../../slices/auth";
import { useKeyboardShortcut } from '../hooks/keyboard';

let timer = null;

function ProductDetail({ selectedProduct, productList }) {
  const dispatch = useDispatch();
  const imgRef = useRef(null);
  const [currentLargeImage, setCurrentLargeImage] = useState(
    selectedProduct.images.primary
  );
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const user = useSelector((state) => state.auth?.currentUser);
  const askellUser = useSelector((state) => state.payments?.customer);

  const [clicked, setClicked] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState('init');
  const paymentDetail = useSelector((state) => state.payments?.payment)
  const modalRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [breakpoint, setBreakpoint] = useState('')
  const [isTouchingImg, setIsTouchingImg] = useState(false);

  let offsetX = 0
  let offsetY = 0


  // useKeyboardShortcut({
  //   key: 'ArrowRight',
  //   onKeyPressed: () => setNextProduct()
  // })
  
  // useKeyboardShortcut({
  //   key: 'ArrowLeft',
  //   onKeyPressed: () => setPreviousProduct()
  // })

  // setting breakpoints
  useEffect(() => {

    function handleResize() {

      const screenWidth = window.innerWidth
      let newBreakpoint
      
      if(screenWidth < 768) {
        newBreakpoint = 'mobile'
      } else if (screenWidth >= 768 && screenWidth <= 992) {
        newBreakpoint = 'tablet'
      } else {
        newBreakpoint = 'desktop'
      }
      
      setBreakpoint(newBreakpoint)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [breakpoint])

  useEffect(() => {
    if (askellUser && askellUser.hasPaymentMethod) {
      setShowCardForm(false)
    }
  }, [askellUser]);

  useEffect(() => {
    setAnchor(`${selectedProduct.id}`);
    setPurchaseStatus('init');
    setClicked(false);
    setAcceptTerms(false);
    setDisabled(false);
  }, [selectedProduct]);

  const setPreviousProduct = () => {
    setAcceptTerms(false)
    const index = productList.findIndex(
      (product) => product.id === selectedProduct.id
    );
    if (index > 0) {
      dispatch(setSelectedProduct(productList[index - 1]));
    } else {
      dispatch(setSelectedProduct(productList[productList.length - 1]));
    }
  };

  const clearSelectedProduct = () => {
    setAcceptTerms(false)
    dispatch(setSelectedProduct(null));
    removeAnchor();
  };

  const setNextProduct = () => {
    setAcceptTerms(false)
    const index = productList.findIndex(
      (product) => product.id === selectedProduct.id
    );
    if (index < productList.length - 1) {
      dispatch(setSelectedProduct(productList[index + 1]));
    } else {
      dispatch(setSelectedProduct(productList[0]));
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setNextProduct(),
    onSwipedRight: () => setPreviousProduct(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false
  });

  const changeLargeImage = (newImage, index) => {
    setCurrentLargeImage(newImage);
    setActiveImageIndex(index);
  };

  useEffect(() => {
    changeLargeImage(selectedProduct.images.primary);
    setActiveImageIndex(null);
  }, [selectedProduct]);

  const getScroll = function () {
    if (window.pageYOffset != undefined) {
      return [pageXOffset, pageYOffset];
    } else {
      var sx,
        sy,
        d = document,
        r = d.documentElement,
        b = d.body;
      sx = r.scrollLeft || b.scrollLeft || 0;
      sy = r.scrollTop || b.scrollTop || 0;
      return [sx, sy];
    }
  };

  const zoom = (e) => {
    const scale = e.currentTarget.getAttribute("data-scale");
    if (imgRef.current) {
      imgRef.current.style.transform = `scale(${scale})`;
    }
  }

  const zoomout = () => {
    if (imgRef.current) {
      imgRef.current.style.transform = "scale(1)";
      imgRef.current.style.backgroundImage =
        imgRef.current.style.backgroundImage.replace("width-1920", "width-584");
    }
  }

  const handleTouchStart = e => {
    setZoomed(true)
    setIsTouchingImg(true);
    zoom(e)

  }
  const handleTouchEnd = e => {
    setZoomed(false)
    setIsTouchingImg(false);
    zoomout()
  }
  const handleTouchMove = e => {

    setIsTouchingImg(true)
    const {touches, changedTouches} = e.originalEvent ?? e
    const touch = touches[0] ?? changedTouches[0]

    const [scrollX, scrollY] = getScroll();
    const position = e.currentTarget.getBoundingClientRect();
     offsetX = touch.pageX - position.x - scrollX;
     offsetY = touch.pageY - position.y - scrollY;

     const originX = (offsetX / e.currentTarget.offsetWidth) * 100;
     const originY = (offsetY / e.currentTarget.offsetHeight) * 100;

     imgRef.current.style.transformOrigin = `${originX}% ${originY}%`;
     imgRef.current.style.backgroundImage =
       imgRef.current.style.backgroundImage.replace("width-584", "width-1920");
  }

  const handleMouseOver = (e) => {
    if (zoomed) {
      zoom(e);
    }
  };

  const handleMouseOut = () => {
    if (zoomed) {
      zoomout()
    }
  };

  const handleMouseMove = (e) => {
    if (zoomed) {
      zoom(e)
      const [scrollX, scrollY] = getScroll();
      if (imgRef.current) {
        const position = e.currentTarget.getBoundingClientRect();
        offsetX = e.pageX - position.x - scrollX;
        offsetY = e.pageY - position.y - scrollY;
        const originX = (offsetX / e.currentTarget.offsetWidth) * 100;
        const originY = (offsetY / e.currentTarget.offsetHeight) * 100;
        imgRef.current.style.transformOrigin = `${originX}% ${originY}%`;
        imgRef.current.style.backgroundImage =
          imgRef.current.style.backgroundImage.replace("width-584", "width-1920");
      }
    } else {
      zoomout()
    }
  };

  const handleClick = (e) => {
    if (!acceptTerms) {
      setClicked(!clicked);
    }

    if (clicked && acceptTerms) {
      setDisabled(true)
      Promise.resolve(dispatch(singleClickPurchase({ product_id: selectedProduct.id })))
        .then((data) => {
          setPurchaseStatus('pending')
          timer = setInterval(() => dispatch(getPayment({ uuid: data.payload.uuid })), 1000)
        })
    }
  };

  const adjustModalPosition = () => {
    const screenHeight = window.innerHeight;
    const modalHeight = modalRef.current?.offsetHeight;

    if (modalHeight && screenHeight < modalHeight) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', adjustModalPosition);
    adjustModalPosition();

    return () => window.removeEventListener('resize', adjustModalPosition);
  }, []);

  const modalStyle = isSmallScreen ? { top: 0, overflow: 'scroll', transform: 'translate(-50%, 0)', paddingBottom: '100px' } : { top: '50%', transform: 'translate(-50%, -50%)' };


  useEffect(() => {
    if (paymentDetail) {
      if (paymentDetail.state === 'settled') {
        clearInterval(timer)
        setPurchaseStatus('done')
      }
      if (paymentDetail.state === 'failed') {
        clearInterval(timer)
        setPurchaseStatus('failed')
      }
    }


  }, [paymentDetail]);

  // algolia returns get_tags instead of tags
  let tags = []
  if (selectedProduct.tags) {
    tags = selectedProduct.tags
  } else if (selectedProduct.get_tags) {
    tags = selectedProduct.get_tags
  }

  return (
    <>
      {(showCardForm) ?
        <div className="container-fluid position-fixed product-detail">
          <div className="row justify-content-center mx-auto clamped product-detail-modal product-creditcard-modal" ref={modalRef} style={modalStyle}>
            <div className="col-xl-12 col-lg-14 col-16 position-relative">
              <div className="bg-card d-flex position-relative overflow-scroll">
                <div
                  className="close position-absolute"
                  onClick={() => setShowCardForm(false)}>
                  <div>
                    <SVGCloseSearchIcon />
                  </div>
                </div>
                <div className="container-fluid inner-container pt-3">
                  <div className="product-container row text">
                    <p className="font-godi title pb-2">Skrá greiðslukort</p>
                    <p className="mb-3">Hér er hægt að skrá greiðslukort fyrir aðganginn þinn.<br /> Athugið að nauðsynlegt er að vera með vistað greiðslukort til þess að kaupa vörur í vefverslun.</p>
                    <CreditCardForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <>
          <div className="container-fluid position-fixed product-detail">
            <div className="row justify-content-center mx-auto clamped product-detail-modal" ref={modalRef} style={modalStyle}>
              <div className="col-xl-12 col-lg-14 col-16 position-relative">
                <div
                  className="previous-product"
                  onClick={() => setPreviousProduct()}>
                  <SVGArrowNext />
                </div>
                <div className={`bg-card d-flex position-relative ${!isTouchingImg ? 'overflow-scroll' : 'overflow-hidden'}`}>
                  <div
                    className="close position-absolute"
                    onClick={() => clearSelectedProduct()}>
                    <div>
                      <SVGCloseSearchIcon />
                    </div>
                  </div>
                  <div className="container-fluid inner-container">
                    <div className="product-container row">
                      <div className="product-detail-images-container d-flex flex-column col-md-9 col-16">
                        <div className="product-detail-image d-flex d-md-block d-lg-flex flex-column">
                          {selectedProduct.description2 && (
                            <h5 className="d-block d-lg-none">
                              {selectedProduct.description2}
                            </h5>
                          )}
                          {(breakpoint === 'desktop' || breakpoint === 'tablet') && (
                            <div className="position-relative">
                              {selectedProduct.price*2 == selectedProduct.base_price && (
                                <div className="banner">
                                {selectedProduct.last_chance ? <SVGLastChance /> : <SVGUtsala />}
                                </div>
                              )}
                              <div
                                className={`product-img-container ${zoomed ? "zoomed" : ""}`}
                                data-scale="4"
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                                onMouseMove={handleMouseMove}
                                onClick={(e) => { if(zoomed){setZoomed(false); zoomout();} else {setZoomed(true); zoom(e)}}}>
                                <div
                                  className="product-img"
                                  ref={imgRef}
                                  style={{
                                    backgroundImage: `url(${currentLargeImage})`,
                                  }}></div>
                              </div>
                            </div>
                          )}

                          {breakpoint === 'mobile' && (
                            <div className="position-relative">
                              <div
                                className={`product-img-container ${zoomed ? "zoomed" : ""}`}
                                data-scale="4"
                                onTouchStart={handleTouchStart}
                                onTouchEnd={handleTouchEnd}
                                onTouchMove={handleTouchMove}

                                >
                                <div
                                    className="product-img"
                                    ref={imgRef}
                                    style={{
                                    backgroundImage: `url(${currentLargeImage})`,
                                }}></div>
                              </div>
                            </div>
                          )}

                        </div>
                        <div className="product-detail-extra-images d-flex flex-row w-100 overflow-scroll">
                          <div
                            className={`img-container d-flex align-items-center justify-content-center ${activeImageIndex === null ? "active" : ""
                              }`}
                            onClick={() =>
                              changeLargeImage(selectedProduct.images.primary, null)
                            }>
                            <img
                              src={selectedProduct.images.primary}
                              className="d-flex mx-auto"
                              alt="Main Thumbnail"
                            />
                          </div>
                          {selectedProduct.images.extra.map((imageSrc, index) => {
                            return (
                              <div
                                className={`img-container ${activeImageIndex === index ? "active" : ""
                                  }`}
                                key={index}
                                onClick={() => changeLargeImage(imageSrc, index)}>
                                <img
                                  src={imageSrc}
                                  className="d-flex"
                                  alt="Extra Thumbnail"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="product-detail-text d-flex flex-column position-relative col-md-7 col-16" {...handlers}>
                        {selectedProduct.category && (
                          <p className="product-category font-abacaxi">
                            {selectedProduct.category}
                          </p>
                        )}
                        <h2 className="product-title d-lg-block">
                          {selectedProduct.title}
                        </h2>
                        <div className="price-container">
                          <div className="price font-godi position-relative">
                            {selectedProduct.base_price !== selectedProduct.price ? <SVGOrangeBg /> : <SVGGreenBg /> }
                            {selectedProduct.base_price !== selectedProduct.price && (
                              <span className="font-godi strikethrough font-small">
                                {formatNumber(selectedProduct.base_price)}kr
                              </span>
                            )}
                            <span className="font-godi product-price">{formatNumber(selectedProduct.price)}kr</span>
                          </div>
                        </div>
                        <div className="product-description mb-5 font-abacaxi" dangerouslySetInnerHTML={{ __html: selectedProduct.description }}></div>
                        <div className="tags font-abacaxi">
                          {tags.map((tag, index) => {
                            return (
                              <a
                                href={`/shop?t=${tag.id}`}
                                className="tag font-abacaxi"
                                key={index}>
                                {tag.title}
                              </a>
                            );
                          })}
                        </div>
                        {/* buy item */}
                        <div className="button-container mb-3">
                          {user && user.authenticated && askellUser && askellUser.hasPaymentMethod &&
                            <>
                              {purchaseStatus === 'done' &&
                                <>
                                  <div className="position-relative purchase-done w-100 d-flex">
                                    <button className="position-relative font-godi me-2 d-flex align-items-center">
                                      <h2 className="text">Vara seld</h2>
                                      <div className="position-absolute svg-container h-100"><SVGMoreButton /></div>
                                    </button>
                                    <h2>... til þín</h2>
                                  </div>
                                  <a href="/minar-sidur/" className="mt-5 font-godi link d-block mx-auto">Skoða mínar pantanir</a>
                                </>
                              }
                              {purchaseStatus === 'failed' &&
                                <>
                                  <div className="purchase-failed position-relative">
                                    <div className="font-var-550 mb-3">Ekki tókst að skuldfæra kort</div>
                                    {/* BÆTA VIÐ ÞENNAN TAKKA MÖGULEIKA Á AÐ REYNA GREIÐSLU AFTUR ? EÐA AÐ ÞESSI TAKKI SÆKI BARA AFTUR KAUPA TAKKANN ?  */}
                                    <button className="position-relative font-godi me-2 d-flex align-items-center">
                                      <span className="text">Reyna aftur</span>
                                      <div className="position-absolute svg-container h-100"><SVGMoreButton /></div>
                                    </button>
                                  </div>
                                </>
                              }
                              {['init', 'pending'].includes(purchaseStatus) &&
                                <>
                                  <div className="mt-3">
                                    {clicked && purchaseStatus !== "pending" &&
                                      <>
                                        <div className="mb-3 font-abacaxi wgt-500">Til að kaupa vöruna þá þarf að samþykkja skilmála Góða Hirðisins. <br />Þegar smellt er á staðfesta kaup verður reynt að skuldfæra á greiðslukortið.</div>
                                        <div className="d-flex mb-3 cursor-pointer">
                                          <input className="me-2 d-none" type="checkbox" id="accept-terms" checked={acceptTerms} onClick={() => setAcceptTerms(!acceptTerms)} />
                                          {acceptTerms ?
                                          <span onClick={() => setAcceptTerms(!acceptTerms)}><Checked /></span>
                                          :
                                          <span onClick={() => setAcceptTerms(!acceptTerms)}><Unchecked /></span>
                                          }
                                          <label htmlFor="accept-terms" className="font-abacaxi wgt-500 ms-2">Samþykkja <a target="_blank" className="link" href="/skilmalar/">skilmála</a> Góða Hirðisins</label>
                                        </div>
                                      </>
                                    }
                                  </div>
                                  <div className="d-flex">
                                    <button
                                      type="button"
                                      className="buy-button font-godi position-relative font-godi text-uppercase text-nowrap"
                                      onClick={() => handleClick()}
                                      disabled={disabled || (clicked && !acceptTerms)}>
                                      {clicked ?
                                        <>{purchaseStatus === 'pending' ?
                                          "Augnablik..."
                                          :
                                          "Staðfesta kaup"
                                        }
                                        </>
                                        :
                                        "Kaupa núna"
                                      }
                                      {/* handleClick fyrir purchase/setPurchase til að fara yfir í kaup */}
                                      <div className="svg-container position-absolute h-100">
                                        <SVGPinkBg />
                                      </div>
                                    </button>
                                  </div>
                                </>
                              }
                            </>
                          }
                          {user && user.authenticated && (askellUser && !askellUser.hasPaymentMethod || !askellUser) &&
                            <>
                              <div className="d-flex">
                                <button
                                  type="button"
                                  className="buy-button font-godi position-relative font-godi text-uppercase text-nowrap mx-auto"
                                  onClick={() => setShowCardForm(true)}>
                                  Bæta við korti
                                  <div className="svg-container position-absolute h-100">
                                    <SVGPinkBg />
                                  </div>
                                </button>
                              </div>
                            </>
                          }

                          {!user || (user && !user.authenticated) &&
                            <div className="d-flex">
                              <button
                                type="button"
                                className="buy-button font-godi position-relative font-godi text-uppercase text-nowrap mx-auto"
                                onClick={() => {
                                  Promise.resolve(dispatch(setShowLogin(true)))
                                    .then(() => clearSelectedProduct())
                                }}>
                                Innskráning
                                <div className="svg-container position-absolute h-100">
                                  <SVGPinkBg />
                                </div>
                              </button>
                            </div>

                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="next-product" onClick={() => setNextProduct()}>
                  <SVGArrowNext />
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}


export default ProductDetail;


