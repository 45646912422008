import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { createTemporaryToken, getCustomer, getTemporaryTokenStatus } from '../../slices/payments';
import { useDispatch } from 'react-redux';
import { addPaymentMethod } from '../../slices/payments';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from './utils';
import { SVGMastercard, SVGPinkBg, SVGVisa } from "../svg";

let timer = null


const CreditCardForm = () => {
  const [display, setDisplay] = useState('form')
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const dispatch = useDispatch()
  const tempToken = useSelector((state) => state.payments?.tempToken)
  const cardVerificationUrl = useSelector((state) => state.payments?.cardVerificationUrl)
  const tokenStatus = useSelector((state) => state.payments?.tokenStatus)
  const tranlations = [
    gettext('Card Number'),
    gettext('Card number is required'),
    gettext('Card number must be 16 digits'),
    gettext('Card number must be 16 digits'),
    gettext('Expiry'),
    gettext('Expiry date is required'),
    gettext('Invalid expiry date'),
    gettext('CVC'),
    gettext('CVC is required'),
    gettext('CVC must be 3 digits'),
    gettext('CVC must be 3 digits'),
    gettext('Save card')
  ]

  const { register, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm({
    defaultValues: {
      number: '',
      expiry: '',
      cvc: '',
    }
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true)
    Promise.resolve(dispatch(createTemporaryToken({
      cardObj: {
        'number': data.number,
        'expiryDate': data.expiry,
        'cvc': data.cvc,
      },
    }))).then((response) => {
      if (timer === null && response.payload.token) {
        timer = setInterval(() => dispatch(getTemporaryTokenStatus({ token: response.payload.token })), 1000)
      }
      if (response.meta.requestStatus !== 'rejected') {
        setDisplay('iframe')
      }
      else {
        setDisplay('form')
      }
      setSubmitDisabled(false)
    })
  }

  useEffect(() => {
    if (tokenStatus === 'tokencreated') {
      clearInterval(timer)
      setDisplay('almost-done')
      let data = {
        'token': tempToken,
      }
      Promise.resolve(dispatch(addPaymentMethod(data))).then((response) => {
        clearInterval(timer)
        setDisplay('done')
        Promise.resolve(dispatch(getCustomer()))
          .then(() => {
            setTimeout(() => {
              reset()
              setDisplay('form')
            }, 5000)
          })
      })
    }
    if (tokenStatus === 'failed') {
      clearInterval(timer)
      setDisplay('failed')
    }
  }, [tokenStatus]);

  return (
    <>
      {(display === 'form' || display === 'failed') &&
        <div className="container-fluid">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-3">
              <label className="form-label">{gettext('Card Number')}</label>
              <input
                type="tel"
                className="form-control"
                onFocus={(e) => setValue('focused', e.target.name)}
                onBlur={(e) => setValue('focused', '')}
                {...register('number', {
                  required: gettext('Card number is required'),
                  minLength: { value: 19, message: gettext('Card number must be 16 digits') },
                  maxLength: { value: 19, message: gettext('Card number must be 16 digits') },
                  onChange: (e) => {
                    setValue('number', formatCreditCardNumber(e.target.value))
                  }
                })}
              />
              {errors.number && <span className='error'>{errors.number.message}</span>}
            </div>
            <div className="row mb-3">
              <div className="col-16 col-md-8 mb-3 mb-md-0">
                <div className="form-group">
                  <label className="form-label">{gettext('Expiry')}</label>
                  <input
                    type="tel"
                    className="form-control"
                    pattern="\d\d/\d\d"
                    onFocus={(e) => setValue('focused', e.target.name)}
                    onBlur={(e) => setValue('focused', '')}
                    {...register('expiry', {
                      required: gettext('Expiry date is required'),
                      pattern: {
                        value: /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
                        message: gettext('Invalid expiry date')
                      },
                      onChange: (e) => {
                        setValue('expiry', formatExpirationDate(e.target.value))
                      }
                    })}
                  />
                  {errors.expiry && <span className='error'>{errors.expiry.message}</span>}
                </div>
              </div>

              <div className="col-16 col-md-8 mb-3 mb-md-0">
                <div className="form-group">
                  <label className="form-label">{gettext('CVC')}</label>
                  <input
                    type="tel"
                    className="form-control"
                    pattern="\d{3,4}"
                    onFocus={(e) => setValue('focused', e.target.name)}
                    onBlur={(e) => setValue('focused', '')}
                    {...register('cvc', {
                      required: gettext('CVC is required'),
                      minLength: { value: 3, message: gettext('CVC must be 3 digits') },
                      maxLength: { value: 3, message: gettext('CVC must be 3 digits') },
                      onChange: (e) => {
                        setValue('cvc', formatCVC(e.target.value))
                      }
                    })}
                  />
                  {errors.cvc && <span className='error'>{errors.cvc.message}</span>}
                </div>
              </div>
            </div>
            <div className="col-16 d-flex justify-content-center">
              <SVGVisa className="test" /><SVGMastercard />
              <button className="btn position-relative font-godi text-uppercase text-nowrap  mx-auto save-card-btn" type="submit">
                {gettext('Save card')}
                <div className="svg-container position-absolute h-100">
                  <SVGPinkBg />
                </div>
              </button>
            </div>
          </form>
        </div>
      }
      {display === 'iframe' &&
        <div className="container">
          <div className="row justify-content-center d-flex">
            <div className="plans-header text-center col-16">
              <iframe id="three-dee-secure" src={cardVerificationUrl} />
            </div>
          </div>
        </div>
      }
      {display === 'done' &&
        <div className="container payment-confirmed">
          <div className="row justify-content-center d-flex">
            <div className="plans-header text-center">
              <h2 className="mb-4">Glæsilegt!</h2>
              <p>Greiðslukortið þitt er nú skráð og þú getur farið að versla.</p>
              <p>Hér inni á mínum síðum er alltaf hægt að setja inn nýtt kort ef þarf.</p>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default CreditCardForm;