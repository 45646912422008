import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductList from '../../components/product_list';
import { setSearchResults, setSearchTerm, clearSearch } from '../../slices/search';
import { SVGSearchContainerBg, SVGSearchIcon, SVGCloseSearchIcon, SVGSearchInputBg } from '../../components/svg';
import ProductDetail from '../../components/product_detail';

export const SearchContainer = () => {
    const appId = window.algolia.appId
    const ix = window.algolia.index
    const frontendKey = window.algolia.frontendKey
    const dispatch = useDispatch()
    const algoliasearch = require("algoliasearch");
    const client = algoliasearch(appId, frontendKey);
    const index = client.initIndex(ix);
    const [searchInput, setSearchInput] = useState('');
    const [searchDisplay, setSearchDisplay] = useState("");
    const searchResults = useSelector((state) => state.search.results)
    const selectedProduct = useSelector(state => state.products.selectedProduct)

    const searchSettings = {
        filters: 'model:godi_hirdirinn_cms.Product AND godi_hirdirinn_cms__Product.show_in_store:true AND godi_hirdirinn_cms__Product.sold:false'
    }

    const searchBox = useRef()


    useEffect(() => {
        if (searchInput.length >= 2) {
            dispatch(setSearchTerm(searchInput))
            index.search(searchInput, searchSettings)
                .then(({ hits }) => {
                    hits = hits.map(hit => {
                        hit.godi_hirdirinn_cms__Product.images = { primary: hit.godi_hirdirinn_cms__Product.primary_image, card: hit.godi_hirdirinn_cms__Product.card_image, extra: hit.godi_hirdirinn_cms__Product.extra_images }
                        return hit
                    })
                    console.log(hits)
                    dispatch(setSearchResults(hits))
                })

            document.querySelector(".search-icon.close").classList.add('d-block');
            document.querySelector(".search-icon.close").classList.remove('d-none');
        } else if (searchInput.length == 0) {
            dispatch(clearSearch())
            document.querySelector(".search-icon.close").classList.add('d-none');
            document.querySelector(".search-icon.close").classList.remove('d-block');
        }
    }, [searchInput])


    return (
        <>
            <div className='row justify-content-center'>
                <div className="col-xl-4 col-lg-6 col-md-8 col-sm-10 col-15 position-relative text-center position-relative">
                    <input ref={searchBox} className="border-0 w-100 font-godi" type="text" placeholder="Gramsaðu í góssinu!" onChange={e => { setSearchInput(e.target.value) }}></input>
                    <div className='position-absolute start-0 search-bg w-100'>
                        <SVGSearchContainerBg />
                        <div className='position-absolute search-icon'><SVGSearchIcon /></div>
                        <div className='position-absolute search-icon close' onClick={() => { searchBox.current.value = ''; setSearchInput('') }}><SVGCloseSearchIcon /></div>
                    </div>
                </div>
            </div>
            {searchInput.length > 0 &&
                <>
                    <div className='row justify-content-center'>
                        <div className="col-12 position-relative mb-5 mt-4">
                            <h2 className="product-block-title d-inline-block me-3">Gramsað eftir: </h2>
                            <h2 className='position-relative d-inline-block search-input'>
                                <div className='position-absolute svg-container'><SVGSearchInputBg /></div>
                                {searchInput}
                            </h2>
                            {searchResults.length == 0 && <h2 className='d-inline-block ms-3'>...en ekkert fannst.</h2>}
                        </div>
                    </div>
                    {searchResults.length > 0 &&
                        <div className='row justify-content-center'>
                            <div className="w-100"></div>
                            <ProductList products={searchResults}></ProductList>
                        </div>
                    }
                    {selectedProduct && (
                        <ProductDetail selectedProduct={selectedProduct} productList={searchResults} />
                    )}

                </>
            }
        </>
    );
};
