import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProductCard } from '../product_card';
import { SVGMoreButton } from '../svg';

function ProductList({ products, show_more_button, button_filter, blockId }) {
    return (
        <>
            {products.map((product, index) => {
                return (
                    <>
                        {index < 4 ?
                            <div className="col-16 col-md-8 col-lg-3 product-card-container" key={index}>
                                <ProductCard product={product} blockId={blockId} />
                            </div>
                            :
                            <div className="col-16 col-md-4 col-lg-2 product-card-container" key={index}>
                                <ProductCard product={product} blockId={blockId} />
                            </div>}
                        {(index == 3 || (index > 3 && (index - 3) % 6 == 0)) &&
                            <div className="w-100" key={'break'}></div>
                        }
                    </>
                )
            })}
            {show_more_button &&
                <div className="w-100 text-center mt-3 mb-5">
                    <a href={`/shop${button_filter}`} className="more-button a-tag font-godi"><SVGMoreButton />Sjá allt</a>
                </div>
            }
        </>
    )
}

export default ProductList;