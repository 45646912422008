import axios from "axios";
import { getCookie } from "../../utils";

const API_URL = "/api/";

const register = (data) => {
    return axios
        .post(API_URL + "signup/", 
            { ...data },
            {
                headers: {
                    'X-CSRFToken': getCookie("csrftoken"),
                },
            }
        ).then((response) => {
            return response.data;
        });
};

const login = (username, password) => {
    return axios
        .post(API_URL + "login/", {
            username,
            password,
        }, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const logout = () => {
    return axios
        .post(API_URL + "logout/", {
        }, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
};

const updateUser = async ({id, data}) => {
    try {
        const res = await fetch(`${API_URL}users/${id}/`, {
            method: 'PUT',
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        const updatedData = await res.json()

        return updatedData

    } catch (err) {
        throw new Error(err)
    }
};

const passwordChange = (email) => {
    return axios
        .post("/password_change/", {
            email: email,
        }, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const passwordReset = (email) => {
    return axios
        .post("/password_reset/", {
            email: email,
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const getCurrentUser = (args) => {
    return axios
        .get(API_URL + 'users/current/', {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const authService = {
    register,
    login,
    logout,
    updateUser,
    passwordChange,
    passwordReset,
    getCurrentUser
};

export default authService;