import axios from "axios";
import { getCookie } from "../../utils";
import { API_BASE_PATH } from "../../const";

const getProducts = (args) => {
    
    return axios
        .get(API_BASE_PATH + `products/${args}`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const getProductsByCategory = (args) => {
    
    return axios
        .get(API_BASE_PATH + `products/all_categories/`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};


const productService = {
    getProducts,
    getProductsByCategory
};

export default productService;