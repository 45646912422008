import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getCurrentUser, registerUser } from "../../slices/auth";
import { Link } from "react-router-dom";
import { SVGPinkBg } from "../svg";
import CreditCardForm from "../creditcard-form";

export default function Signup() {
    const dispatch = useDispatch();
    const authenticated = useSelector((state) => state.auth.isLoggedIn);
    const { register, handleSubmit, clearErrors, setError, formState: { errors } } = useForm();
    const [step, setStep] = useState(1);
    const translations = [
        gettext('Personal information'),
        gettext('Already a user, log out if you want to create a new user.'),
        gettext('First name'),
        gettext('Last name'),
        gettext('SSN'),
        gettext('Phone'),
        gettext('Email'),
        gettext('Password'),
        gettext('Repeat password'),
        gettext('Continue'),
        gettext('Creditcard information'),
        gettext('Already a user, log out if you want to create a new user.')
    ]

    const onSubmit = async (data) => {
        clearErrors()
        dispatch(registerUser(data)).then((data) => {
            if (data.type.includes('rejected') || data.payload.error) {
                if (data.type.includes('rejected')) {
                    setError('generic', { type: "custom", message: "Villa kom upp. Vinsamlegast reyndu aftur." })
                }
                if (data.payload.error) {
                    Object.keys(data.payload.error).map((key) => {
                        setError(key, { type: "custom", message: data.payload.error[key].join(", ") })
                    })
                }
            }
            else {
                dispatch(getCurrentUser())
                setStep(2)
            }
        })
    };

    return (
        <div className="container signup-component">
            <div className="row justify-content-center d-flex">
                <div className="col-lg-12 mt-5 d-flex flex-column">
                    {step === 1 &&
                        <>
                            <h2 className="font-abacaxi font-var-550 mb-4">{gettext('Personal information')}</h2>
                            {authenticated ?
                                <div className="text-center font-bold already-user">{gettext('Already a user, log out if you want to create a new user.')}</div>
                                :
                                <>
                                    <form onSubmit={handleSubmit(onSubmit)} className="row">
                                        {errors.generic && <div className="error">{errors.generic.message}</div>}
                                        <div className="form-group position-relative mb-3 col-xl-8">
                                            <label htmlFor="first_name" className="form-label">{gettext('First name')}</label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.first_name ? "is-invalid" : ""}`}
                                                id="first_name"
                                                {...register('first_name', { required: 'Field cannot be blank' })}
                                            />
                                            {errors.first_name && <div className="error">{errors.first_name.message}</div>}
                                        </div>

                                        <div className="form-group position-relative mb-3 col-xl-8">
                                            <label htmlFor="last_name" className="form-label">{gettext('Last name')}</label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.last_name ? "is-invalid" : ""}`}
                                                id="last_name"
                                                {...register('last_name', { required: 'Field cannot be blank' })}
                                            />
                                            {errors.last_name && <div className="error">{errors.last_name.message}</div>}
                                        </div>

                                        <div className="form-group position-relative mb-3 col-xl-8">
                                            <label htmlFor="ssn" className="form-label">{gettext('SSN')}</label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.ssn ? "is-invalid" : ""}`}
                                                id="ssn"
                                                {...register('ssn', { required: 'Field cannot be blank' })}
                                            />
                                            {errors.ssn && <div className="error">{errors.ssn.message}</div>}
                                        </div>

                                        <div className="form-group position-relative mb-3 col-xl-8">
                                            <label htmlFor="phone" className="form-label">{gettext('Phone')}</label>
                                            <input
                                                type="tel"
                                                className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                                                id="phone"
                                                {...register('phone', { required: 'Field cannot be blank' })}
                                            />
                                            {errors.phone && <div className="error">{errors.phone.message}</div>}
                                        </div>

                                        <div className="form-group position-relative mb-3 col-xl-8">
                                            <label htmlFor="email" className="form-label">{gettext('Email')}</label>
                                            <input
                                                type="email"
                                                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                                id="email"
                                                {...register('email', {
                                                    required: 'Field cannot be blank',
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        message: 'Invalid email address'
                                                    }
                                                })}
                                            />
                                            {errors.email && <div className="error">{errors.email.message}</div>}
                                        </div>

                                        <div className="form-group position-relative mb-3 col-xl-8">
                                            <label htmlFor="password1" className="form-label">{gettext('Password')}</label>
                                            <input
                                                type="password"
                                                className={`form-control ${errors.password1 ? "is-invalid" : ""}`}
                                                id="password1"
                                                {...register("password1", { required: 'Field cannot be blank' })}
                                            />
                                            {errors.password1 && <div className="error">{errors.password1.message}</div>}
                                        </div>

                                        <div className="form-group position-relative mb-3 col-xl-8">
                                            <label htmlFor="password2" className="form-label">{gettext('Repeat password')}</label>
                                            <input
                                                type="password"
                                                className={`form-control ${errors.password2 ? "is-invalid" : ""}`}
                                                id="password2"
                                                {...register("password2", { required: 'Field cannot be blank' })}
                                            />
                                            {errors.password2 && <div className="error">{errors.password2.message}</div>}
                                        </div>
                                        <div className="col-16 d-flex justify-content-center">
                                            <button className="btn position-relative font-godi mx-auto" type="submit">
                                                {gettext('Continue')}
                                                <div className="position-absolute svg-container h-100"><SVGPinkBg /></div>
                                            </button>
                                        </div>
                                    </form>
                                </>
                            }
                        </>
                    }
                    {step === 2 &&
                        <>
                            <h2 className="font-abacaxi font-var-550 mb-4">{gettext('Creditcard information')}</h2>
                            {authenticated ?
                                <div className="text-center font-bold already-user">{gettext('Already a user, log out if you want to create a new user.')}</div>
                                :
                                <>
                                    <div className="row justify-content-center">
                                        <div className="col-16">
                                            <p>Notandinn þinn hefur verið stofnaður, og nú er hægt að vista greiðslukort. Þar sem vefverslun Góða hirðisins notast ekki við körfu er aðeins ýtt á „kaupa núna" hnappinn og pöntunin fer þá tafarlaust í gegn, greiðsla skuldfærist af kortinu og varan er þín.</p>
                                        </div>
                                        <CreditCardForm />
                                    </div>

                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}
