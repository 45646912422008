import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../../store'
import LoginModalContainer from './loginmodal-container';

export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <LoginModalContainer {...props}/>
        </Provider>
    )
}