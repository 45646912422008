import React from 'react';
import ReactDOM from 'react-dom/client';
import Wrapper from './wrapper';

function productBlockContainer(element) {
  var options = element.dataset;
  var root = ReactDOM.createRoot(element);
  root.render(React.createElement(Wrapper, options));
}

document.addEventListener("DOMContentLoaded", function() {
  var elements = document.querySelectorAll('[data-productblock-container]');
  elements.forEach(productBlockContainer);
});