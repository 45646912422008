import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AskellService from "../../api/askell";

export const createTemporaryToken = createAsyncThunk(
  "temporaryToken/create",
  async ({ cardObj, planId }, thunkAPI) => {
    try {
      const response = await AskellService.createTemporaryToken(cardObj, planId);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
); 

export const getTemporaryTokenStatus = createAsyncThunk(
  "temporaryToken/get",
  async ({ token }, thunkAPI) => {
    try {
      const response = await AskellService.getTemporaryTokenStatus(token);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
); 

export const addPaymentMethod = createAsyncThunk(
  "paymentMethod/create",
  async (data, thunkAPI) => {
    try {
      const response = await AskellService.addPaymentMethod(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCustomer = createAsyncThunk(
  "askellcustomers/get",
  async (data, thunkAPI) => {
    try {
      const response = await AskellService.getCustomerDetails();
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPayment = createAsyncThunk(
  "payment/get",
  async ({ uuid }, thunkAPI) => {
    try {
      const response = await AskellService.getPaymentDetails(uuid);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { tempToken: null, cardVerificationUrl: null, error: null, tokenStatus: null, customer: {hasPaymentMethod: false} };

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  extraReducers: {
    [createTemporaryToken.fulfilled]: (state, action) => {
      state.tempToken = action.payload.token;
      state.cardVerificationUrl = action.payload.card_verification_url;
      state.tokenStatus = 'pending'
      state.error = null
    },
    [createTemporaryToken.rejected]: (state, action) => {
      state.tempToken = null;
      state.cardVerificationUrl = null;
      state.error = "Ekki tókst að vista kort";
      state.tokenStatus = null
    },
    [getTemporaryTokenStatus.fulfilled]: (state, action) => {
      state.tokenStatus = action.payload.status
      if (action.payload.status === 'failed') {
        state.cardVerificationUrl = null
        state.tempToken = null
        state.error = "Ekki tókst að staðfesta kort."
      }
      state.error = null
    },
    [getTemporaryTokenStatus.rejected]: (state, action) => {
      state.tokenStatus = action.payload.status
      state.error = "Ekki tókst að staðfesta kort."
    },
    [getCustomer.fulfilled]: (state, action) => {
      let customer = action.payload.response;
      if (customer.payment_method) {
        customer.payment_method = customer.payment_method.filter(f => f.verified && !f.canceled)
        customer.hasPaymentMethod = customer.payment_method.length > 0
      } 
      state.customer = customer
      state.error = null
    },
    [getCustomer.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja viðskiptavin";
    },
    [getPayment.fulfilled]: (state, action) => {
      state.payment = action.payload.response;
      state.error = null
    },
    [getPayment.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja greiðslu";
    },
  },
});

const { reducer } = paymentSlice;
export default reducer;