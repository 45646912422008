import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useClickAway } from '@uidotdev/usehooks'
import { MODAL_LOGGED_IN } from '../../const';
import { getCurrentUser, logout } from "../../slices/auth";
import { setShowLogin } from "../../slices/auth";
import { SVGLockIcon, SVGMenuArrows } from "../svg";
import LoginModal from '../modals/login_modal';

function Userbar() {
    const [minarSidurDropdownOpen, setMinarSidurDropdownOpen] = useState(false)
    const toggleMinarSidurDropdown = () => {
        setMinarSidurDropdownOpen(!minarSidurDropdownOpen)
    }
    const currentUser = useSelector(state => state.auth.currentUser);

    const ref = useClickAway(() => {
        setMinarSidurDropdownOpen(false);
    });
    const dispatch = useDispatch();

    const Logout = () => {
        dispatch(logout());
    }


    return (
        <>
            {currentUser !== null ? (
                Object.keys(currentUser).length > 0 ? (
                    <ul className="navbar-nav right-nav d-flex flex-row">
                        <li className={`nav-item d-flex me-0 p-0 align-items-center my-pages-container ${minarSidurDropdownOpen && 'dropdown-open'}`}>
                            <div className="login-menu position-relative">
                                <div className="dropdown" ref={ref} >
                                    <div className="nav-link login d-flex align-items-center" onClick={toggleMinarSidurDropdown}>
                                        <div className="d-block me-3">{currentUser.first_name}</div>
                                        <SVGMenuArrows />
                                    </div>
                                    <ul className={`dropdown-menu border-0 ${minarSidurDropdownOpen ? 'd-block' : 'd-none'}`}>
                                        <li><a className="dropdown-item d-flex align-items-center border-0 mb-5" onClick={() => { window.location.href = `/minar-sidur`; }}>Mínar upplýsingar</a></li>
                                        <li className='log-out'><button className="btn d-flex mx-auto mt-4 mb-2 position-relative" onClick={() => Logout()}>
                                            <span>Útskrá</span>
                                            <svg className="position-absolute start-0 top-0 w-100 h-100" preserveAspectRatio="none" viewBox="0 0 158 40">
                                                <path fill="#f16549" fillRule="evenodd" d="M2.5 0 0 33.2 148.4 40 158 .6z"
                                                    clipRule="evenodd" />
                                            </svg>
                                        </button></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                ) : (
                    <ul className="navbar-nav right-nav d-flex flex-row">
                        <li className={`nav-item d-flex me-0 p-0 align-items-center my-pages-container not-logged-in`}>
                            <div className="login-menu position-relative">
                                <div className="dropdown">
                                    <div className="nav-link login d-flex align-items-center" onClick={() => dispatch(setShowLogin(true))}>
                                        <div className="d-block">
                                            <span className='me-2'>Innskráning</span>
                                            <SVGLockIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                ))
                : (<></>)
            }
        </>
    );
}

export default Userbar;