import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { login } from "../../slices/auth";
import { Link } from "react-router-dom";
import { passwordReset } from "../../slices/auth";
import Login from "./login";
import { SVGPinkBg } from "../svg";

export default function ForgotPassword() {
    const dispatch = useDispatch()

    const email = useRef()
    const password = useRef()
    const [showLogin, setShowLogin] = useState(false);
    const [passwordResetSent, setPasswordResetSent] = useState(false);
    const translations = [
        gettext('Back to login'),
        gettext('Reset password'),
        gettext('Back to login')
    ]
    useEffect(() => {

    }, [])

    const resetPW = (e) => {
        e.preventDefault()
        Promise.resolve(dispatch(passwordReset(email.current.value)))
            .then((data) => {
                setPasswordResetSent(true)
            })
    }


    return (
        showLogin ? (
            <Login />
        ) : (
            <div className="container forgotten-password-component">

                <div className="row justify-content-center d-flex ">
                    {passwordResetSent ?
                        <div className="justify-content-center password-reset-msg">
                            <div className="mt-5 col-12 mx-auto password-reset-text">
                                <p>Ef netfangið er til á skrá þá munum við senda upplýsingar um endursetningu lykilorðs á það.
                                    Ef þú færð ekki sendan tölvupóst, athugaðu að þú hafir slegið inn rétt netfang og að það hafi ekki endað í spam síunni þinni.
                                </p>
                            </div>
                            <button type="button" className="btn btn-link back-to-login mx-auto d-flex" onClick={() => setShowLogin(true)}>{gettext('Back to login')}</button>
                        </div>
                        :
                        <div className="col-lg-6 mt-5">

                            <form onSubmit={resetPW}>

                                <div className="form-group position-relative mb-3">
                                    <label className="form-label" htmlFor={`email-login`}>{('Email')}</label>
                                    <input placeholder=" " ref={email} type="email" className="form-control" aria-describedby="emailHelp" id={`email-login`} />
                                </div>

                                <div className="modal-footer d-flex flex-column justify-content-center">
                                    <button type="submit" className="btn barlow semibold mb-5 position-relative font-godi">{gettext('Reset password')}
                                        <div className="position-absolute svg-container"><SVGPinkBg /></div>
                                    </button>

                                    <div className="d-flex mb-3">
                                        <button type="button" className="btn btn-link back-to-login" onClick={() => setShowLogin(true)}>{gettext('Back to login')}</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    }</div>
            </div>
        )

    );
}