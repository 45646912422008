import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../../store'
import WebshopContainer from './webshop-container';
import { BrowserRouter as Router } from 'react-router-dom';

export default function Wrapper(props) {
    return (
        <Router>
            <Provider store={store}>
                <WebshopContainer {...props} />
            </Provider>
        </Router>
    )
}